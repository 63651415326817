import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 30px 0",
	"md-padding": "60px 0 30px 0",
	"quarkly-title": "Footer-15"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "36px 34px",
			"lg-grid-template-columns": "repeat(3, 1fr)",
			"lg-flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"padding": "0px 50px 0px 0",
			"md-width": "90%",
			"sm-margin": "40px 0px 0px 0",
			"sm-width": "100%",
			"sm-padding": "0px 0px 0px 0",
			"margin": "0px 0 0px 0px",
			"lg-width": "100%",
			"lg-padding": "0px 0 0px 0"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "AutoMasters"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"lg-max-width": "640px",
			"children": "Ваш надійний партнер на дорозі - сервісний центр AutoMasters"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "25%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"width": "25%",
			"padding": "0px 0 0px 0px",
			"lg-width": "30%",
			"md-width": "45%",
			"sm-width": "100%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "Швидка автодопомога"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+380673429990",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"children": "+380673429990"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@nimbelix.com",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"children": "contact@nimbelix.com"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"width": "25%",
			"lg-width": "30%",
			"md-width": "45%",
			"sm-width": "100%"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "Адреса"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"children": <>
				вул. Вербова, 1/2
				<br />
				77400, м. Тисмениця, Івано-Франківська обл.
			</>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")} />
			<Box {...override("box3")}>
				<Text {...override("text2")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text5")} />
				<Text {...override("text6")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;